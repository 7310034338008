import React, { useContext } from "react";
import AppContext from "../../../../../appContext";
import "./ModalStakeBGOF.css";

import BgofBtClose from "../../../assets/images/BgofBtClose.png";
import BgofBtConfirm from "../../../assets/images/BgofBtConfirm.png";
import BgofModal from "../../../assets/images/BgofModal.png";

function ModalStakeBGOFContinue({ handleStakeBGOFContinue }) {
  const { setModalBGOFOpen } = useContext(AppContext);

  return (
    <div className="modalBackground">
      <div style={{ position: "relative" }}>
        <div
          style={{
            position: "absolute",
            top: "-30px",
            right: "-30px",
            cursor: "pointer",
          }}
          onClick={() => {
            setModalBGOFOpen(false);
          }}
        >
          <img src={BgofBtClose} alt={BgofBtClose} />
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "90px",
            left: "50%",
            transform: "translateX(-50%)",
            cursor: "pointer",
          }}
          onClick={handleStakeBGOFContinue}
        >
          <img src={BgofBtConfirm} alt={BgofBtConfirm} />
        </div>

        <img src={BgofModal} alt={BgofModal} />
        <div style={{ position: "absolute" }}></div>
      </div>
    </div>
  );
}

export default ModalStakeBGOFContinue;

{
  /* <button
            onClick={() => {
              setModalBGOFOpen(false);
            }}
          >
            X
          </button> */
}

/**
 * <div className="titleCloseBtn">
          
        </div>

<div className="footer">
          <button
            id="cancelBtn"
            onClick={() => {
              setModalBGOFOpen(false);
            }}
          >
            Cancel
          </button>
          <button onClick={handleStakeBGOFContinue}>Continue</button>
        </div>

        style={{ position: "absolute", bottom: "23%", left: "37.2%" }}
 */

/**
         * 
         * <div style={{ position: "relative" }}>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            setModalBGOFOpen(false);
          }}
        >
          <img src={BgofBtClose} alt={BgofBtClose} />
        </div>
        <img src={BgofModal} alt={BgofModal} />
        <div
          style={{ cursor: "pointer", display: "block" }}
          onClick={handleStakeBGOFContinue}
        >
          <img src={BgofBtConfirm} alt={BgofBtConfirm} />
        </div>
      </div>
         */
