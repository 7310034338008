import React, { useContext, useEffect } from 'react';
import Header from '../components/layout/Header';
import AppContext from '../../../appContext';
import '../assets/scss/landingPage.scoped.scss';
import { Dimmer, Loader } from 'semantic-ui-react';

const LayoutDefault = ({ children, metoTokens, daiTokens }) => {
  const { screenBlocked } = useContext(AppContext);
  useEffect(() => {
    screenBlocked
      ? (document.getElementsByTagName('body')[0].style.overflowY = 'hidden')
      : (document.getElementsByTagName('body')[0].style.overflowY = 'scroll');
  }, [screenBlocked]);
  return (
    <>
      <Header navPosition="right" className="reveal-from-bottom" />
      <main className="site-content">{children}</main>
      <Dimmer active={screenBlocked} style={{ zIndex: '1000000000000', position: 'fixed' }}>
        <Loader indeterminate content="Waiting for transaction to finish......" />
      </Dimmer>
    </>
  );
};

export default LayoutDefault;
