import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import getWeb3 from './web3-config';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from "@sentry/react";
import {  Integrations } from "@sentry/tracing";
//inject web3 into the App.js

Sentry.init({
    dsn: "https://ce5a08a52c7542a5833e859adca857f5@o4503924049772544.ingest.sentry.io/4503924056522752",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
});
getWeb3().then(web3 => {
    ReactDOM.render(
        <BrowserRouter>
            <App web3={web3} />
        </BrowserRouter>,
        document.getElementById('root')
    );
});

serviceWorker.unregister();