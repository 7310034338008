import React, { useState } from "react";
// import sections
import Hero from "../components/sections/Hero";
import "../assets/scss/landingPage.scoped.scss";
import ItemStaking from "../components/sections/ItemStaking";
import ModalHistory from "../components/sections/ModalDeposit";
import { BGOF_TO_BGOF, PACKAGE_12_MONTHS } from "../../../config";

import GuideDetail12Month from "../components/sections/GuideDetail12Month";

const Home12Month = ({
  from,
  balance,
  isApproveToTransfer,
  onApprove,
  onStake,
  historyByPackage,
  onUnBlock,
  onHarvest,
  onOnlyHarvest,
  isReStakeMode
}) => {
  const [openDeposit, setOpenDeposit] = useState(false);
  let selectedPackage = React.useRef(1);

  const toggleDeposit = () => setOpenDeposit(!openDeposit);

  const setPackageDeposit = async (type) => {
    if (!isApproveToTransfer) {
      await onApprove();
    }

    selectedPackage.current = type;
    toggleDeposit();
  };

  const handleStaking = async (coin) => {
    await onStake(selectedPackage.current, coin);
    toggleDeposit();
  };

  const handleApprove = async (type) => {
    selectedPackage.current = type;
    const isApproveSuccess = await onApprove();
    if (isApproveSuccess) {
      toggleDeposit();
    }
  };

  const styleBackground = "changeBackground";

  if (from == BGOF_TO_BGOF) {
    return (
      <>
        <Hero />
        <div
          className="container ui doubling stackable three column grid"
          id="main-page"
        >
          <ItemStaking
            styleBackground={styleBackground}
            from={from}
            isCenter={true}
            package={PACKAGE_12_MONTHS}
            history={historyByPackage[PACKAGE_12_MONTHS]}
            title="12 Months"
            apr={10}
            packageId={3}
            toggleDeposit={() => setPackageDeposit(PACKAGE_12_MONTHS)}
            onUnBlock={onUnBlock}
            onHarvest={onHarvest}
            onOnlyHarvest={onOnlyHarvest}
            isApproveToTransfer={isApproveToTransfer}
            onApprove={() => handleApprove(PACKAGE_12_MONTHS)}
            isReStakeMode={isReStakeMode}
          />
        </div>
        <div
          className="container"
          style={{ position: "relative", textAlign: "center", marginTop: 20 }}
        >
          <h1
            className="mt-0 mb-16 reveal-from-bottom text-primary text-white"
            data-reveal-delay="200"
          >
            UNLOCKING RATE & TERM{" "}
          </h1>
          <GuideDetail12Month />
        </div>
        <ModalHistory
          open={openDeposit}
          toggleModal={toggleDeposit}
          selectedPackage={selectedPackage.current}
          balance={balance}
          onSubmit={handleStaking}
        />
      </>
    );
  }
};

export default Home12Month;
