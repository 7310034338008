import React, { useState, useContext, useEffect } from "react";
import { Image, Card, Button, Popup } from "semantic-ui-react";

import ItemHistory from "./ItemHistory";

import cardImg from "../../assets/images/card.png";

import BFGT from "../../assets/images/BFGT.png";
import BGOF from "../../assets/images/BGOF.png";
import arrowRight from "../../assets/images/right-arrow.png";
import roiIcon from "../../assets/images/icon-roi.svg";
import arrDownIcon from "../../assets/images/arrow-down.svg";
import AppContext from "../../../../appContext";
import { handleConnect } from "../../../../utils/metamask";
import { Icon } from "semantic-ui-react";

import { isMobile } from "react-device-detect";

import {
  BGOF_TO_BGOF,
  PACKAGE_PROFIT,
  PACKAGE_TITLE,
} from "../../../../config";
import ChevronLeft from "../elements/ChevronLeft";

function ItemStaking({
  styleBackground,
  from,
  toggleDeposit,
  history = [],
  onUnBlock,
  onHarvest,
  onOnlyHarvest,
  packageId = 3,
  isApproveToTransfer,
  onApprove,
  isReStakeMode
}) {
  const { account, networkId } = useContext(AppContext);

  useEffect(() => {
    setOpenHistory(false);
  }, [account]);

  const [isOpenHistory, setOpenHistory] = useState(false);
  const [historyIndex, setHistoryIndex] = useState(0);

  const toggleHistory = () => setOpenHistory(!isOpenHistory);

  const isDisabledButton = networkId != process.env.REACT_APP_NETWORK_ID;

  return (
    <div className={`container-stake-card`}>
      <div
        className="stake-card"
      // style={{
      //   backgroundImage: `url(${cardImg})`,
      // }}
      >
        <div
          className={`main-card ${isOpenHistory ? "main-margin-bottom " : ""}`}
        >
          <div className="icon-swap">
            {/* <Image src={bnbIcon} className="icon-coin" /> */}
            <Image
              src={from == BGOF_TO_BGOF ? BGOF : BFGT}
              className={from == BGOF_TO_BGOF ? "BGOF-coin" : "BFGT-coin"}
              width="32px"
            />
            <Image src={arrowRight} className="arrow" />
            <Image src={BGOF} className="BGOF-coin" width="32px" />
          </div>
          <p className="title">BGOF</p>
          <p className="subtitle">
            Stake {`${from == BGOF_TO_BGOF ? "BGOF" : "BFGT"}`} to earn BGOF
          </p>

          <div className="flex-row">
            <span className="text-primary" style={{ fontSize: 16 }}>
              {`${PACKAGE_TITLE[packageId].toUpperCase()}`}
            </span>
            <span className="text-bold apr">
              {`${PACKAGE_PROFIT[packageId].earningRate}%`}
              <Popup
                style={{ cursor: "pointer" }}
                // content={`- Profits can be withdrawn at any time\n- Staking Rate/Month : ${PACKAGE_PROFIT[packageId].ratePerMonth}%`}
                trigger={<Image src={roiIcon} className="icon-roi" />}
              >
                <Popup.Content>
                  <span className="medium-text">
                    {`-Rate : `}
                    <span className="hover-roi">{`${PACKAGE_PROFIT[packageId].ratePerMonth}%/Month`}</span>
                  </span>
                  <br />

                  <span className="medium-text">
                    -Profits can be withdrawn at every 1 month
                  </span>
                </Popup.Content>
              </Popup>
            </span>
          </div>
          <div className="btn-container">
            {isMobile && !account && !window.ethereum ? (
              <a
                href={process.env.REACT_APP_DEEPLINK_METAMASK}
                className="btn-stake medium-text"
                onClick={handleConnect}
              >
                CONNECT METAMASK
              </a>
            ) : !isApproveToTransfer ? (
              <Button
                className={`btn-stake medium-text ${isDisabledButton ? "btn-disabled" : ""
                  }`}
                onClick={account ? onApprove : handleConnect}
                disabled={isDisabledButton}
              >
                {account ? "ENABLE" : "CONNECT WALLET"}
              </Button>
            ) : (
              <Button
                className={`btn-stake medium-text ${isDisabledButton ? "btn-disabled" : ""
                  }`}
                onClick={account ? toggleDeposit : handleConnect}
                disabled={isDisabledButton}
              >
                {account
                  ? from == BGOF_TO_BGOF
                    ? "STAKE BGOF"
                    : "STAKE BGFT"
                  : "CONNECT WALLET"}
              </Button>
            )}
          </div>
          <div
            className={`${history.length >= 0
              ? styleBackground
                ? styleBackground
                : "guide"
              : "guide radius-bottom"
              }`}
          >
            <p className="guide-title">{`${PACKAGE_TITLE[packageId]} Staking`}</p>
            <p className="guide-content">{`Earning Rate : ${PACKAGE_PROFIT[packageId].earningRate}%`}</p>
            <p className="guide-content">{`Staking Rate/Month : ${PACKAGE_PROFIT[packageId].ratePerMonth}%`}</p>
            <p className="guide-content" style={{ minHeight: 50 }}>
              {packageId != 3
                ? ` *Cancel The Lockup After ${PACKAGE_TITLE[packageId]} Of Staking (Principal After For
              ${PACKAGE_PROFIT[packageId].timeLock} Month)`
                : ` *Cancel The Lockup After ${PACKAGE_TITLE[packageId]} Of Staking`}
            </p>
          </div>

          {!isOpenHistory && (
            <div
              className={`btn-detail ${history.length > 0 ? "" : "display-none"
                }`}
              onClick={toggleHistory}
            >
              <span style={{ fontSize: 14, color: "#000" }}>Show More</span>
              <Image src={arrDownIcon} className={`arrow-down`} />
            </div>
          )}
          {isOpenHistory && history.length > 0 && (
            <div
              className={`tab-history ${isOpenHistory ? "" : "display-none"}`}
            >
              <ItemHistory
                data={history[historyIndex]}
                onUnBlock={onUnBlock}
                onHarvest={onHarvest}
                onOnlyHarvest={onOnlyHarvest}
                isReStakeMode={isReStakeMode}
              />
              <div className="tab-paginate-btns" style={{ marginBottom: 8 }}>
                <button
                  onClick={() => setHistoryIndex(historyIndex - 1)}
                  disabled={historyIndex <= 0}
                >
                  <ChevronLeft width="18px" />
                </button>
                <button
                  onClick={() => setHistoryIndex(historyIndex + 1)}
                  disabled={historyIndex >= history.length - 1}
                >
                  <ChevronLeft className="right-icon" width="18px" />
                </button>
              </div>
              <div className="tab-paginate-btns" style={{ color: "#000" }}>
                <span className="history-time">
                  Page {historyIndex + 1}/{history.length}
                </span>
              </div>

              {/* {history.map((item) => (
              <ItemHistory key={item.id.toString()} data={item} onUnBlock={onUnBlock} onHarvest={onHarvest} />
    
                ))} */}

              <div className="tab-history-footer">
                <a
                  rel="noopener noreferrer"
                  className="view-bsc"
                  target="_blank"
                  href={`${process.env.REACT_APP_LINK_BSC}/${process.env.REACT_APP_TOKEN_FARM_ADDRESS}`}
                >
                  View on BSCscan
                </a>
                <div
                  className={`btn-detail ${history.length ? "" : "display-none"
                    }`}
                  onClick={toggleHistory}
                >
                  <span style={{ fontSize: 14, color: "#000" }}>Show Less</span>
                  <Image src={arrDownIcon} className="arrow-down arrow-up" />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ItemStaking;
