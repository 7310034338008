import React from "react";
import { isMobile } from "react-device-detect";


const NotiStep1 = () => {

  return (
    <div>
      {
        isMobile ? <img src="/images/step1.png" alt=""></img> : <img src="/images/step1.png" alt=""></img>
      }
    </div>
  );
};

export default NotiStep1;
