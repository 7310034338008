import React, { useContext, useState } from "react";
import styles from "./BannerNotiIAgreen.module.css";
import axios from "axios";
import { isMobile } from "react-device-detect";
import appContext from "../../../../../../../appContext";
import { getBGOFTokenOldContractInstance } from "../../../../../../../utils/assets";
import PopupClaimSuccess from "../../PopupClaimSuccess";
import { toast } from "react-toastify";

const BannerNotiIAgreen = ({
  popupNotiClaim,
  setPopupNotiClaim,
  setBannerNoti,
  isDisabledClaim,
  setIsDisabledClaim,
  web3,
  account,
}) => {
  const { setLoading } = useContext(appContext);
  const [isMoveOutSide, setIsMoveOutSide] = useState(false)

  const handleClickButtonClaim = async () => {
    try {
      const bgofTokenOldContract = getBGOFTokenOldContractInstance(web3);
      const spenderAddress = process.env.REACT_APP_BGOF_FARM_SPENDER + "";
      setIsDisabledClaim(true);
      setLoading(true);


      const [gasLimit, userBalance] = await Promise.all([
        bgofTokenOldContract.methods
          .approve(spenderAddress, 0).estimateGas({ from: account }),
        web3.eth.getBalance(account)
      ])

      const transactionFee = +gasLimit * 10000000000

      if (transactionFee > +userBalance) {
        toast.error("Not enough gas fee ", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading(false);
        setPopupNotiClaim(false);
        return
      }

      await bgofTokenOldContract.methods
        .approve(spenderAddress, 0)
        .send({ from: account });
      setPopupNotiClaim(true);
      updateStatus();
    } catch (error) {
      setLoading(false);
      setIsDisabledClaim(false);
    }

  };

  //   const handleClickButtonClaimAnother = async () => {
  //     setPopupNotiClaim(true);
  //     setBannerNoti(false);
  //   };

  const updateStatus = async () => {
    try {
      const url = process.env.REACT_APP_API_UPDATE_USER_WHITE_LISTS + "";
      const urlMain = `${url}/${account}`;
      await axios.put(urlMain);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <>
      <div style={{ position: 'relative' }}>

        <div onMouseLeave={() => setIsMoveOutSide(true)} onMouseMove={() => setIsMoveOutSide(false)}>
          <div className={isMoveOutSide && styles.banner_outSide}>
            <div
              className={
                isDisabledClaim ? styles.btn_claim_disabled : styles.btn_claim
              }
              onClick={handleClickButtonClaim}
            >
              <div></div>
              <img src="/images/btn-Iagreen.png" className={styles.btn_receive} alt=""></img>
            </div>
            {
              isMobile ? <img src="/images/BannerIagreen_m.png" alt=""></img> : <img src="/images/BannerIagreen.png" alt=""></img>
            }

          </div>
        </div>
      </div>
    </>
  );
};

export default BannerNotiIAgreen;
