import React, {
  useRef,
  useEffect,
  useCallback,
  useContext,
  useState,
} from "react";
import { useLocation, useHistory } from "react-router-dom";
import ScrollReveal from "./utils/ScrollReveal";
import AppContext from "../../appContext";
import "../landingPage/assets/scss/landingPage.scoped.scss";
import {
  approveCHTSTokensToTransfer,
  convertToTokens,
  checkCHTSTokensFor,
  // getInfoPackage,
  stakeToFarm,
  getHistoryStake,
  getCurrentClaimInfo,
  claimProfit,
  claimStake,
  changeContractConfig,
  stakeToBGOF,
  checkBGOFApprove,
  approveBGOFTokensToTransfer,
  checkBalanceOfUserBeforeRestake,
  getIsReStakeFlag,
} from "../../utils/assets";
// Layouts
import LayoutDefault from "./layouts/LayoutDefault";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import notifyBG from "../landingPage/assets/images/bg-notify.svg";
import iconSuccess from "../landingPage/assets/images/icon-success.svg";

// Views
import Home12Month from "./views/Home12Month";

import {
  PACKAGE_6_MONTHS,
  PACKAGE_12_MONTHS,
  PACKAGE_3_MONTHS,
  BGOF_TO_BGOF,
} from "../../config";

import ModalSetting from "./components/sections/ModalSetting";
import ModalStakeBGOFContinue from "./components/sections/ModalBGOF/ModalStakeBGOFContinue";

const BgofStacking = () => {
  const {
    web3,
    account,
    handleBlockScreen,
    screenBlocked,
    openSetting,
    modalBGOFOpen,
    setModalBGOFOpen,
    toggleSetting,
    totalHarvest,
    setTotalHarvest,
  } = useContext(AppContext);
  const childRef = useRef();

  const history = useHistory();
  let location = useLocation();

  changeContractConfig(BGOF_TO_BGOF);
  const Layout =
    LayoutDefault === undefined
      ? (props) => <>{props.children}</>
      : LayoutDefault;

  const [CHTSToken, setCHTSToken] = useState(0);

  const [isApproveToTransfer, setApprove] = useState(0);
  const [historyByPackage, setHistory] = useState({
    [PACKAGE_6_MONTHS]: [],
    [PACKAGE_12_MONTHS]: [],
    [PACKAGE_3_MONTHS]: [],
  });

  const [isReStakeMode, setIsReStakeMode] = useState(false);

  const notify = (message, isFailed = false) => {
    if (isFailed === false)
      toast.success(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    else {
      toast.error(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleTokensChange = useCallback(async () => {
    handleBlockScreen(true);
    try {
      const { balance, isApprove } = await checkCHTSTokensFor(web3, account);

      function floorFigure(figure, decimals) {
        if (!decimals) decimals = 2;
        var d = Math.pow(10, decimals);
        return Number((parseInt(figure * d) / d).toFixed(decimals));
      }

      setCHTSToken(floorFigure(+convertToTokens(balance, web3), 3));
      setApprove(isApprove);

      // const { packageOne, packageTwo, packageThree } = await getInfoPackage(
      //   web3
      // );

      const history = await getHistoryStake(web3, account);

      const { resultProfit, resultStake } = await getCurrentClaimInfo(
        web3,
        Object.keys(history)
      );

      Object.keys(resultProfit).forEach((key) => {
        history[key].profitCanClaim =
          resultProfit[key] - history[key].profitClaimed;
      });

      Object.keys(resultStake).forEach((key) => {
        history[key].stakeCanClaim =
          resultStake[key] - history[key].stakeClaimed;
      });

      const newHistoryByPackage = {
        [PACKAGE_6_MONTHS]: [],
        [PACKAGE_12_MONTHS]: [],
        [PACKAGE_3_MONTHS]: [],
      };

      Object.keys(history).forEach((profileId) => {
        const packageId = history[profileId].packageId;
        newHistoryByPackage[packageId].push(history[profileId]);
      });

      setHistory(newHistoryByPackage);

      // Get is restake mode
      const isReStakeMode = await getIsReStakeFlag(web3);
      setIsReStakeMode(isReStakeMode);
    } catch (e) {}

    handleBlockScreen(false);
  }, [account, web3]);

  const handleHistory = async () => {
    if (screenBlocked) {
      return;
    }

    try {
      const history = await getHistoryStake(web3, account);

      const { resultProfit, resultStake } = await getCurrentClaimInfo(
        web3,
        Object.keys(history)
      );

      Object.keys(resultProfit).forEach((key) => {
        history[key].profitCanClaim =
          resultProfit[key] - history[key].profitClaimed;
      });

      Object.keys(resultStake).forEach((key) => {
        history[key].stakeCanClaim =
          resultStake[key] - history[key].stakeClaimed;
      });

      const newHistoryByPackage = {
        [PACKAGE_6_MONTHS]: [],
        [PACKAGE_12_MONTHS]: [],
        [PACKAGE_3_MONTHS]: [],
      };

      Object.keys(history).forEach((profileId) => {
        const packageId = history[profileId].packageId;
        newHistoryByPackage[packageId].push(history[profileId]);
      });

      setHistory(newHistoryByPackage);
    } catch (e) {}
  };

  useEffect(() => {
    (async () => {
      //it could happen that account is empty when this useEffect runs initially, hence the guard
      if (account) {
        handleTokensChange();
      } else {
        setHistory({});
      }
    })();
  }, [account, handleTokensChange]);

  useEffect(() => {
    document.body.classList.add("is-loaded");
    childRef.current.init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  let intervalId = useRef(0);

  useEffect(() => {
    if (account) {
      intervalId.current = setInterval(handleHistory, 10000);
    } else {
      clearInterval(intervalId.current);
    }

    return () => clearInterval(intervalId.current);
  }, [web3, account]);

  const onApproveToTransfer = useCallback(async () => {
    handleBlockScreen(true);
    try {
      await approveCHTSTokensToTransfer(web3, account);
      setApprove(true);
      handleBlockScreen(false);

      return true;
    } catch (e) {
      const error = e.message;

      if (error.includes("insufficient funds")) {
        notify("Insufficient BNB", true);
      } else {
        notify("Enable failed", true);
      }
    }
    handleBlockScreen(false);
    return false;
  }, [web3, account]);

  const onStake = useCallback(
    async (type, numberCoin) => {
      handleBlockScreen(true);
      try {
        await stakeToFarm(web3, account, numberCoin, type);
        const { balance } = await checkCHTSTokensFor(web3, account);
        setCHTSToken(+convertToTokens(balance, web3));
        await handleTokensChange();
        notify("Staking success");
      } catch (e) {
        const error = e.message;
        if (
          error.includes("insufficient funds") ||
          error.includes("exceeds allowance")
        ) {
          notify("Insufficient BNB");
        } else {
          notify("Withdrawal failed", true);
        }
      }

      handleBlockScreen(false);
    },
    [web3, account]
  );

  const onStakeBGOF = useCallback(async () => {
    handleBlockScreen(true);
    try {
      await stakeToBGOF(web3, account, totalHarvest, 3);
      notify("Staking success");
    } catch (e) {
      const error = e.message;
      if (
        error.includes("insufficient funds") ||
        error.includes("exceeds allowance")
      ) {
        notify("Insufficient BNB");
      } else {
        notify("Withdrawal failed", true);
      }
    }

    handleBlockScreen(false);
    history.push("/bgof");
  }, [web3, account, totalHarvest]);

  const handleStakeBGOFContinue = async () => {
    setModalBGOFOpen(false);
    handleBlockScreen(true);
    try {
      const checkApproveBGOF = await checkBGOFApprove(web3, account);
      const checkFeeGasOfUser = await checkBalanceOfUserBeforeRestake(
        web3,
        account
      );

      if (checkApproveBGOF.isApprove && checkFeeGasOfUser.isApprove) {
        handleBlockScreen(true);
        await onStakeBGOF();
      } else {
        if (checkFeeGasOfUser.isApprove) {
          handleBlockScreen(true);
          await approveBGOFTokensToTransfer(web3, account);
          handleStakeBGOFContinue();
        } else {
          notify("Insufficient BNB", true);
        }
      }
    } catch (error) {
      const err = error.message;
      if (
        err.includes("insufficient funds") ||
        err.includes("exceeds allowance")
      ) {
        notify("Insufficient BNB");
      } else {
        notify("Withdrawal failed", true);
      }
    }
    handleBlockScreen(false);
  };

  const onHarvest = useCallback(
    async (profileId, total) => {
      handleBlockScreen(true);
      // try {
      try {
        await claimProfit(web3, account, profileId);
        setTotalHarvest(total);
        setModalBGOFOpen(true);
        await handleTokensChange();
        notify("Withdrawal success");
      } catch (e) {
        const error = e.message;
        if (
          error.includes("insufficient funds") ||
          error.includes("exceeds allowance")
        ) {
          notify("Insufficient BNB");
        } else {
          notify("Withdrawal failed", true);
        }
      }
      // } catch (e) {
      //   const error = e.message;
      //   alert(e.message);
      //   if (error.includes("insufficient funds")) {
      //     notify("Insufficient BNB");
      //   } else {
      //     notify("Withdrawal failed", true);
      //   }
      // }

      handleBlockScreen(false);
    },
    [web3, account]
  );

  const onOnlyHarvest = useCallback(
    async (profileId, total, isReStakeMode) => {
      handleBlockScreen(true);
      // try {
      try {
        await claimProfit(web3, account, profileId);

        setTotalHarvest(total);

        await handleTokensChange();
        isReStakeMode
          ? notify(
              `Restake success! Check your profiles in 'BGOF Staking' menu.`
            )
          : notify("Harvest success");
        // setModalBGOFOpen(true);
      } catch (e) {
        const error = e.message;
        if (
          error.includes("insufficient funds") ||
          error.includes("exceeds allowance")
        ) {
          notify("Insufficient BNB");
        } else {
          isReStakeMode
            ? notify("ReStake failed", true)
            : notify("Harvest failed", true);
        }
      }
      // } catch (e) {
      //   const error = e.message;
      //   alert(e.message);
      //   if (error.includes("insufficient funds")) {
      //     notify("Insufficient BNB");
      //   } else {
      //     notify("Withdrawal failed", true);
      //   }
      // }

      handleBlockScreen(false);
    },
    [web3, account]
  );

  const onUnBlock = useCallback(
    async (profileId) => {
      handleBlockScreen(true);
      try {
        await claimStake(web3, account, profileId);
        await handleTokensChange();
        notify("Unlock success");
      } catch (e) {
        const error = e.message;

        if (error.includes("insufficient funds")) {
          notify("Insufficient BNB", true);
        } else {
          notify("Unlock failed");
        }
      }

      handleBlockScreen(false);
    },
    [web3, account]
  );

  return (
    <>
      {modalBGOFOpen && (
        <ModalStakeBGOFContinue
          handleStakeBGOFContinue={handleStakeBGOFContinue}
        />
      )}
      <ScrollReveal
        ref={childRef}
        children={() => (
          <Layout>
            <Home12Month
              from={BGOF_TO_BGOF}
              balance={CHTSToken}
              isApproveToTransfer={isApproveToTransfer}
              onApprove={onApproveToTransfer}
              onStake={onStake}
              historyByPackage={historyByPackage}
              c={onHarvest}
              onOnlyHarvest={onOnlyHarvest}
              onUnBlock={onUnBlock}
              isReStakeMode={isReStakeMode}
            />
            <ModalSetting
              open={openSetting}
              balance={CHTSToken}
              account={account}
              toggleModal={toggleSetting}
            />
          </Layout>
        )}
      />
    </>
  );
};

export default BgofStacking;
