//if metamask is installed but not connected
export const handleConnect = async () => {
  await window.ethereum.enable();
};

export const handleLogout = async () => {
  await window.ethereum.clearCachedProvider();
};

export async function handleChangeNetwork() {
  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: process.env.REACT_APP_CHAIN_ID }],
    });
  } catch (err) {
    if (err.code === 4902 || err.code === -32603) {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: process.env.REACT_APP_CHAIN_ID,
            rpcUrls: [process.env.REACT_APP_RPC_URL],
            chainName: process.env.REACT_APP_CHAIN_NAME,
            nativeCurrency: {
              symbol: process.env.REACT_APP_CHAIN_SYMBOL,
              decimals: 18,
            },
            blockExplorerUrls: [process.env.REACT_APP_EXPLORE],
          },
        ],
      });
    }
  }
}

// export const handleChangeNetwork = async () => {
//   await window.ethereum.request({
//     method: "wallet_addEthereumChain",
//     params: [
//       {
//         chainId: process.env.REACT_APP_CHAIN_ID,
//         rpcUrls: [process.env.REACT_APP_RPC_URL],
//         chainName: process.env.REACT_APP_CHAIN_NAME,
//         nativeCurrency: {
//           name: "BNB",
//           symbol: "BNB", // 2-6 characters long
//           decimals: 18,
//         },
//       },
//     ],
//   });
// };

//if metamask is not installed at all
export const handleInstall = () => {
  window.open("https://metamask.io/download.html", "_blank");
};
