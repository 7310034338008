import React from "react";
import { isMobile } from "react-device-detect";
import styles from "./NotiMobiStep2.module.css";


const NotiMobiStep2 = () => {

  return (
    <div className={styles.bg_img_mobi}>
      {
        isMobile ? <img className={styles.img_mobi} src="/images/img-mobi-step2.png" alt=""></img> : <img src="/images/img-mobi-step2.png" alt=""></img>
      }
    </div>
  );
};

export default NotiMobiStep2;
