import React, { useState } from "react";
import { isMobile } from "react-device-detect";
//import PopupClaimSuccess from "../../PopupClaimSuccess";
import styles from "./NotiStep2.module.css";


const NotiStep2 = () => {

  return (
    <div>
      {
        isMobile ? <img src="/images/step2.png" alt=""></img> : <img src="/images/step2.png" alt=""></img>
      }
    </div>
  );
};

export default NotiStep2;
