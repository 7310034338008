import React, { useState, useContext } from "react";
import { Button, Card, Image } from "semantic-ui-react";

import chtsIcon from "../../assets/images/BGOF.png";
import imgUnblock from "../../assets/images/BFGT.png";
import { formatTotalCoin, getTime } from "../../../../utils/helper";
import ReactTooltip from "react-tooltip";
import ModalStakeBGOFContinue from "./ModalBGOF/ModalStakeBGOFContinue";
import AppContext from "../../../../appContext";
import {
  approveBGOFTokensToTransfer,
  checkCHTSTokensFor,
} from "../../../../utils/assets";

const DATA_BY_MONTH = {
  1: 6,
  2: 9,
  3: 12,
};

const DATA_INTEREST_BY_MONTH = {
  1: 2,
  2: 3,
  3: 4,
};

const DATA_INTEREST = {
  1: 12,
  2: 27,
  3: 48,
};

function ItemHistory({
  data,
  onUnBlock,
  onHarvest,
  onOnlyHarvest,
  isLast = false,
  isReStakeMode = false,
}) {
  const handleUnBlock = () => onUnBlock(data.id);

  // const handleHarvest = () =>
  //   onHarvest(data.id, data?.packageId, data?.profitCanClaim);

  const handleHarvest = () => onHarvest(data.id, data?.profitCanClaim);

  // False: Only harvest not restake
  const handleOnlyHarvest = () =>
    onOnlyHarvest(data.id, data?.profitCanClaim, false);

  // True: Force restake
  const handleReStake = () =>
    onOnlyHarvest(data.id, data?.profitCanClaim, true);

  // const handleHarvest = async () => {
  //   try {
  //     await onHarvest(data.id, data?.packageId, data?.profitCanClaim);

  //     setModalBGOFOpen(true);
  //     return (
  //       <div>
  //         {modalBGOFOpen && (
  //           <ModalStakeBGOFContinue
  //             handleStakeBGOFContinue={handleStakeBGOFContinue}
  //           />
  //         )}
  //       </div>
  //     );
  //   } catch (error) {}
  // };

  const totalEarnedValue = formatTotalCoin(
    data.profitClaimed + data.stakeClaimed
  );
  const totalStakedValue = formatTotalCoin(data.amount);
  const stakeCanClaim =
    +data?.stakeCanClaim > 0
      ? formatTotalCoin(data?.stakeCanClaim)
      : formatTotalCoin(0);

  const profitCanClaim = formatTotalCoin(data?.profitCanClaim);
  const monthlyInterest = formatTotalCoin(
    (data.amount * DATA_INTEREST_BY_MONTH[data.packageId]) / 100
  );

  const totalEarnValue = formatTotalCoin(
    (data.amount * DATA_INTEREST[data.packageId]) / 100
  );

  const disableHarvest = !data.profitCanClaim;
  const disableUnBlock = !data.stakeCanClaim;

  const getDiffDay = () => {
    const date1 = new Date(data.vestingStart * 1000);
    const date2 = new Date();
    const diffTime = Math.abs(date2 - date1);
    const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;
    const ONE_SECOND_IN_MS = 1 * 1000;

    // Testnet use ONE_MINUTE_IN_MS
    // return ONE_SECOND_IN_MS;

    // Mainnet use ONE_DAY_IN_MS
    const diffDays = Math.ceil(diffTime / ONE_DAY_IN_MS);
    return diffDays;
  };

  const acceptWithdraw = () => {
    const divideProfit = data.totalProfit / DATA_BY_MONTH[data.packageId];

    if (
      (data?.profitCanClaim >= divideProfit && getDiffDay() >= 30) ||
      getDiffDay() >= DATA_BY_MONTH[data.packageId] * 30
    ) {
      return true;
    }
    return false;

    // if (data?.profitCanClaim >= divideProfit) {
    //   return true;
    // }
    // return false;
  };

  return (
    <div className={`history-card ${isLast ? "" : "border-bottom"}`}>
      <div className="flex-row margin-y-5" style={{ justifyContent: "center" }}>
        <span>
          {isReStakeMode && (
            <Button
              className="btn-mini"
              id="btn-harvest"
              variant="transparent"
              onClick={handleReStake}
              disabled={disableHarvest || !acceptWithdraw()} // HARD CODE
              data-tip={
                disableHarvest
                  ? "You have no profit to harvest."
                  : !acceptWithdraw()
                  ? "You have to stake at least 1 month to harvest the profit"
                  : null
              }
            >
              ReStake
            </Button>
          )}
          {!isReStakeMode && (
            <Button
              className="btn-mini-center"
              id="btn-harvest"
              variant="transparent"
              onClick={handleOnlyHarvest}
              disabled={disableHarvest || !acceptWithdraw()} // HARD CODE
              data-tip={
                disableHarvest
                  ? "You have no profit to harvest."
                  : !acceptWithdraw()
                  ? "You have to stake at least 1 month to harvest the profit"
                  : null
              }
            >
              Harvest
            </Button>
          )}
          <Button
            id="btn-unblock"
            className=" btn-mini"
            style={{ marginLeft: 10 }}
            onClick={handleUnBlock}
            disabled={disableUnBlock}
            data-tip={disableUnBlock ? "No value to unlock." : null}
          >
            Unlock Stake
          </Button>
        </span>
      </div>
      <div className="flex-row margin-y-2">
        <p className="margin-y-2 small-text">Total Staked Value:</p>
        <p className="margin-y-2 medium-text">{totalStakedValue}</p>
      </div>
      <div className="flex-row margin-y-2">
        <p className="margin-y-2 small-text">Total Claimed Value:</p>
        <p className="margin-y-2 medium-text">{totalEarnedValue}</p>
      </div>
      <div className="flex-row margin-y-2">
        <p className="margin-y-2 small-text">{`Total Earn Value (${
          DATA_INTEREST[data.packageId]
        }%):`}</p>
        <p className="margin-y-2 medium-text">{totalEarnValue}</p>
      </div>
      <div className="flex-row margin-y-2">
        <p className="margin-y-2 small-text">{`Monthly Interest (${
          DATA_INTEREST_BY_MONTH[data.packageId]
        }%):`}</p>
        <p className="margin-y-2 medium-text">{monthlyInterest}</p>
      </div>
      <div className="flex-row margin-y-2">
        <p className="margin-y-2 small-text">Stake Unlock:</p>
        <span className="history-staked-value margin-y-2 flex">
          <Image src={imgUnblock} className="icon-chts" />
          {stakeCanClaim}
        </span>
      </div>
      <div className="flex-row margin-y-2">
        <p className="margin-y-2 small-text">Earned Value:</p>
        <span className="history-staked-value margin-y-2 flex">
          <Image src={chtsIcon} className="icon-chts" />
          {profitCanClaim}
        </span>
      </div>
      <div
        className="flex-row margin-y-2 justify-center"
        style={{ marginTop: 8 }}
      >
        <p className="history-time">
          {getTime(new Date(+data.vestingStart * 1000))}
        </p>
      </div>
    </div>
  );
}

export default ItemHistory;
