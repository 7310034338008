import React from "react";
import { isMobile } from "react-device-detect";
import styles from "./NotiMobiStep4.module.css";


const NotiMobiStep4 = () => {

  return (
    <div className={styles.bg_img_mobi}>
      {
        isMobile ? <img className={styles.img_mobi} src="/images/img-mobi-step4.png" alt=""></img> : <img src="/images/img-mobi-step4.png" alt=""></img>
      }
    </div>
  );
};

export default NotiMobiStep4;
