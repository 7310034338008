import React, { useContext } from "react";
import AppContext from "../../../../../appContext";

const PopupClaimSuccess = () => {
  const { setBannerNoti, setPopupNotiClaim, setLoading } =
    useContext(AppContext);
  return (
    <div className="modalBackground">
      <div style={{ position: "relative" }}>
        <div
          style={{
            position: "absolute",
            top: "30px",
            right: "20px",
            cursor: "pointer",
          }}
          onClick={() => {
            setPopupNotiClaim(false);
            setBannerNoti(false);
            setLoading(false);
          }}
        >
          <img src="/images/ButtonClosePopupSuccess.png" />
        </div>
        <img src="/images/PopupClaimSuccess.png" />
        <div style={{ position: "absolute" }}></div>
      </div>
      {/* <img src="/images/PopupClaimSuccess.png"></img> */}
    </div>
  );
};

export default PopupClaimSuccess;
