import React, { useState, useEffect, useRef } from 'react';
import BannerNotiIAgreen from './notiStep4/BannerNotiIAgreen';
import NotiStep1 from './NotiStep1/NotiStep1';
import NotiStep2 from './notiStep2/NotiStep2';
import NotiStep3 from './notiStep3/NotiStep3';
import styles from "./BannerNotiStep.module.css";
import PopupClaimSuccess from '../PopupClaimSuccess';
import { isMobile } from "react-device-detect";
import NotiMobiStep1 from './NotiMobiStep1/NotiMobiStep1';
import NotiMobiStep2 from './NotiMobiStep2/NotiMobiStep2';
import NotiMobiStep3 from './NotiMobiStep3/NotiMobiStep3';
import NotiMobiStep4 from './NotiMobiStep4/NotiMobiStep4';
import NotiMobiStep5 from './NotiMobiStep5/NotiMobiStep5';


const BannerNotiStep = ({
  web3,
  account,
  whiteLists,
  popupNotiClaim,
  setPopupNotiClaim,
  setBannerNoti,
  isDisabledClaim,
  setIsDisabledClaim,
  handleBlockScreen,
}) => {
  const [banners, setBanners] = useState([
    <NotiStep1 />,
    <NotiStep2 />,
    <NotiStep3 />,
    <BannerNotiIAgreen
      web3={web3}
      account={account}
      whiteLists={whiteLists}
      popupNotiClaim={popupNotiClaim}
      setPopupNotiClaim={setPopupNotiClaim}
      setBannerNoti={setBannerNoti}
      isDisabledClaim={isDisabledClaim}
      setIsDisabledClaim={setIsDisabledClaim}
      handleBlockScreen={handleBlockScreen}
    />,

  ]);

  const [mobiBanners, setMobiBanners] = useState([
    <NotiMobiStep1 />,
    <NotiMobiStep2 />,
    <NotiMobiStep3 />,
    <NotiMobiStep4 />,
    <NotiMobiStep5 />,
    <BannerNotiIAgreen
      web3={web3}
      account={account}
      whiteLists={whiteLists}
      popupNotiClaim={popupNotiClaim}
      setPopupNotiClaim={setPopupNotiClaim}
      setBannerNoti={setBannerNoti}
      isDisabledClaim={isDisabledClaim}
      setIsDisabledClaim={setIsDisabledClaim}
      handleBlockScreen={handleBlockScreen}
    />,

  ]);

  console.log('isMobile', isMobile)




  const [isMoveOutSide, setIsMoveOutSide] = useState(false)

  const [currentBanner, setCurrentBanner] = useState(banners[0]);
  const [currentBannerMobi, setCurrentBannerMobi] = useState(mobiBanners[0]);
  const [bannerIndex, setBannerIndex] = useState(1);


  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     bannerIndex = (bannerIndex + 1) % banners.length;
  //     setCurrentBanner(banners[bannerIndex]);
  //   }, 3000);
  //   return () => clearInterval(interval);
  // }, [banners]);



  const handlePrev = () => {
    if (isMobile === true) {
      setBannerIndex((bannerIndex - 1 + 6) % 6)
      setCurrentBannerMobi(mobiBanners[bannerIndex]);
    } else {
      setBannerIndex((bannerIndex - 1 + 4) % 4)
      setCurrentBanner(banners[bannerIndex]);
    }

  };

  const handleNext = () => {
    if (isMobile === true) {
      setBannerIndex((bannerIndex + 1) % 6)
      setCurrentBannerMobi(mobiBanners[bannerIndex]);
    } else {
      setBannerIndex((bannerIndex + 1) % 4)
      setCurrentBanner(banners[bannerIndex]);
    }

  };


  return (

    <>
      {<PopupClaimSuccess />}


      {isMobile
        ? (<div className={`modalBackground`}>
          <div className={isMoveOutSide && styles.banner_outSide} >
            {/* {bannerIndex === 1
              ? ""
              : (<div className={styles.grp_btn_mobi_pev} onClick={handlePrev}>
                <img className={styles.btn_mobi_pev} src="/images/btn-pev-mobi.png" alt="" />
              </div>)
            } */}
            <div style={{ position: "relative" }}>
              {currentBannerMobi}
              {bannerIndex === 0
                ? ""
                : (<div className={styles.grp_btn_mobi_next} onClick={handleNext}>
                  <button className={styles.btn_next_mobi}> Next</button>
                </div>)
              }
            </div>
          </div >
        </div>)

        : (<div className={`modalBackground`}>
          <div className={isMoveOutSide && styles.banner_outSide} >
            <div style={{ position: "relative" }}>
              {currentBanner}
              {bannerIndex === 0
                ? ""
                : (<div className={styles.grp_btn_next} onClick={handleNext}>
                  <button className={styles.btn_claim}> Next</button>
                </div>)
              }
            </div>
          </div >
        </div>)
      }

    </>


  );
};

export default BannerNotiStep;


{/* <div className={
          styles.btn_claim
        }
          onClick={handlePrev} >
          <img className={styles.btn_receive} alt='' src='/images/Next.png' />
        </div> */}