import React from "react";
import { isMobile } from "react-device-detect";
import styles from "./NotiMobiStep3.module.css";

const NotiMobiStep3 = () => {

  return (
    <div className={styles.bg_img_mobi}>
      {
        isMobile ? <img className={styles.img_mobi} src="/images/img-mobi-step3.png" alt=""></img> : <img src="/images/img-mobi-step3.png" alt=""></img>
      }
    </div>
  );
};

export default NotiMobiStep3;
