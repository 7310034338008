export const TOKEN_FARM_ADDRESS = "0x897844a8C12687963b914EFc800585eb57cbd456";
//"0xDd5e3429668c8fB20Ed6dbf24e05a0eE10B77818";
//"0x551cAB95A51D9965fE499045f27319cF28f82F7f";
// "0xa9d61d8727b880efe0038225444d3f7791ec90c6";
export const CHTS_TOKEN_ADDRESS = "0x6999171fE1e531B89b0A4ef6d10158e8B4384d3C";
//"0x795008a7854BeEcf116E06838a072e1fe4F675f4";

export const LINK_BSC_TESTNET = "https://testnet.bscscan.com/address";

export const PACKAGE_3_MONTHS = 1;
export const PACKAGE_6_MONTHS = 2;
export const PACKAGE_12_MONTHS = 3;

export const BFGT_TO_BGOF = 1;
export const BGOF_TO_BGOF = 2;

export const PACKAGE_TITLE = {
  [PACKAGE_3_MONTHS]: "6 Months",
  [PACKAGE_6_MONTHS]: "9 Months",
  [PACKAGE_12_MONTHS]: "12 Months",
};

export const PACKAGE_PROFIT = {
  [PACKAGE_3_MONTHS]: {
    earningRate: 12,
    apr: 12,
    timeLock: 6,
    ratePerMonth: 2
  },
  [PACKAGE_6_MONTHS]: {
    earningRate:27,
    apr: 27,
    timeLock: 9,
    ratePerMonth: 3
  },
  [PACKAGE_12_MONTHS]: {
    earningRate: 48,
    apr: 48,
    timeLock: 12,
    ratePerMonth: 4
  },
};
