import TokenFarm from "../contracts/TokenFarm.json";
import CHTSToken from "../contracts/CHTSToken.json";
import {
  PACKAGE_6_MONTHS,
  PACKAGE_12_MONTHS,
  PACKAGE_3_MONTHS,
  BGOF_TO_BGOF,
} from "../config";

let TOKEN_FARM_ADDRESS = process.env.REACT_APP_TOKEN_FARM_ADDRESS;
let CHTS_TOKEN_ADDRESS = process.env.REACT_APP_CHTS_TOKEN_ADDRESS;
const BGOF_TOKEN_ADDRESS = process.env.REACT_APP_BGOF_TOKEN_ADDRESS;
const BGOF_TOKEN_OLD_ADDRESS = process.env.REACT_APP_BGOF_TOKEN_OLD_ADDRESS;
const BGOF_FARM_ADDRESS = process.env.REACT_APP_TOKEN_STAKE_BGOF_ADDRESS;

export const changeContractConfig = (mode) => {
  if (mode == BGOF_TO_BGOF) {
    TOKEN_FARM_ADDRESS = process.env.REACT_APP_TOKEN_STAKE_BGOF_ADDRESS;
    CHTS_TOKEN_ADDRESS = process.env.REACT_APP_BGOF_TOKEN_ADDRESS;
  } else {
    TOKEN_FARM_ADDRESS = process.env.REACT_APP_TOKEN_FARM_ADDRESS;
    CHTS_TOKEN_ADDRESS = process.env.REACT_APP_CHTS_TOKEN_ADDRESS;
  }
};

export const getCHTSTokenContractInstance = (web3) => {
  return new web3.eth.Contract(CHTSToken, CHTS_TOKEN_ADDRESS);
};

export const getBGOFTokenContractInstance = (web3) =>
  new web3.eth.Contract(CHTSToken, BGOF_TOKEN_ADDRESS);

export const getBGOFTokenOldContractInstance = (web3) => {
  console.log("BGOF_TOKEN_OLD_ADDRESS", BGOF_TOKEN_OLD_ADDRESS)
  return new web3.eth.Contract(CHTSToken, BGOF_TOKEN_OLD_ADDRESS);
}


export const getTokenFarmContractInstance = (web3) => {
  return new web3.eth.Contract(TokenFarm, TOKEN_FARM_ADDRESS);
};

export const checkCHTSTokensFor = async (web3, account) => {
  const chtsContract = getCHTSTokenContractInstance(web3);
  const bgofContract = getBGOFTokenContractInstance(web3);

  const [balance, allowance, bgofBalance] = await Promise.all([
    chtsContract.methods.balanceOf(account).call(),
    chtsContract.methods.allowance(account, TOKEN_FARM_ADDRESS).call(),
    bgofContract.methods.balanceOf(account).call(),
  ]);

  return {
    balance,
    isApprove: allowance > 100 * 10 ** 18,
  };
};

export const checkBalanceOfUserBeforeRestake = async (web3, account) => {
  const balanceOfUser = await new web3.eth.getBalance(account);
  return {
    isApprove: balanceOfUser > 0.005 * 10 ** 18,
  };
};

export const checkBGOFApprove = async (web3, account) => {
  const bgofContract = new web3.eth.Contract(CHTSToken, BGOF_TOKEN_ADDRESS);

  const [allowance] = await Promise.all([
    bgofContract.methods.allowance(account, BGOF_FARM_ADDRESS).call(),
  ]);

  return {
    isApprove: allowance > 100 * 10 ** 18,
  };
};

export const getInfoPackage = async (web3) => {
  const tokenContract = new web3.eth.Contract(TokenFarm, TOKEN_FARM_ADDRESS);
  const [packageOne, packageTwo, packageThree] = await Promise.all([
    tokenContract.methods.packages(PACKAGE_3_MONTHS).call(),
    tokenContract.methods.packages(PACKAGE_6_MONTHS).call(),
    tokenContract.methods.packages(PACKAGE_12_MONTHS).call(),
  ]);

  return {
    packageOne,
    packageTwo,
    packageThree,
  };
};

export const getIsReStakeFlag = async (web3) => {
  const tokenContract = new web3.eth.Contract(TokenFarm, TOKEN_FARM_ADDRESS);
  const isReStake = await tokenContract.methods.isRestake().call();
  console.log("isReStake:", isReStake);
  return isReStake;
};

export const stakeToBGOF = async (
  web3,
  account,
  totalCoin,
  selectedPackage
) => {
  const tokenContract = new web3.eth.Contract(TokenFarm, BGOF_FARM_ADDRESS);
  const coinBGOF = convertToWei(totalCoin.toFixed(17), web3);

  const data = await tokenContract.methods
    .stake(coinBGOF, selectedPackage)
    .send({ from: account });
  return data;
};

export const stakeToFarm = async (
  web3,
  account,
  totalCoin,
  selectedPackage
) => {
  const tokenContract = new web3.eth.Contract(TokenFarm, TOKEN_FARM_ADDRESS);
  const coinCHTS = convertToWei(totalCoin.toString(), web3);
  // const dataGas = await tokenContract.methods
  //   .stake(coinCHTS, selectedPackage)
  //   .estimateGas({ from: account });
  // console.log("dataGas: ", dataGas / 100000000);

  // console.log("dataGas: ", (dataGas * 1.5) / 100000000);

  // throw Error("Insufficient fund");

  const data = await tokenContract.methods
    .stake(coinCHTS, selectedPackage)
    .send({ from: account });
  return data;
};

export const claimProfit = async (web3, account, profileId) => {
  const tokenContract = new web3.eth.Contract(TokenFarm, TOKEN_FARM_ADDRESS);
  const data = await tokenContract.methods
    .claimProfit(profileId)
    .send({ from: account });
  return data;
};

export const claimStake = async (web3, account, profileId) => {
  const tokenContract = new web3.eth.Contract(TokenFarm, TOKEN_FARM_ADDRESS);
  const data = await tokenContract.methods
    .claimStaking(profileId)
    .send({ from: account });
  return data;
};

export const getHistoryStake = async (web3, account) => {
  const tokenContract = new web3.eth.Contract(TokenFarm, TOKEN_FARM_ADDRESS);
  try {
    const data = await tokenContract.methods
      .getProfilesByAddress(account)
      .call();

    const history = {};
    data.forEach((item) => {
      const dataA = {
        id: item[0],
        address: item[1],
        amount: +convertToTokens(item[2], web3), // How many tokens the user has provided.
        profitClaimed: +convertToTokens(item[3], web3), // default false
        stakeClaimed: +convertToTokens(item[4], web3), // default false
        vestingStart: item[5],
        vestingEnd: item[6],
        totalProfit: +convertToTokens(item[7], web3),
        packageId: item[8],
        refunded: item[9],
      };
      history[dataA.id] = dataA;
    });

    return history;
  } catch (e) { }

  return [];
};

export const approveCHTSTokensToTransfer = async (web3, account) => {
  const chtsContract = getCHTSTokenContractInstance(web3);

  const coin = convertToWei("100000000", web3);

  const data = await chtsContract.methods
    .approve(TOKEN_FARM_ADDRESS, coin)
    .send({ from: account });

  return data;
};

export const approveBGOFTokensToTransfer = async (web3, account) => {
  // const chtsContract = getCHTSTokenContractInstance(web3);

  const bgofContract = new web3.eth.Contract(CHTSToken, BGOF_TOKEN_ADDRESS);

  const coin = convertToWei("100000000", web3);

  const data = await bgofContract.methods
    .approve(BGOF_FARM_ADDRESS, coin)
    .send({ from: account });

  return data;
};

export const getCurrentClaimInfo = async (web3, listProfileId = []) => {
  const tokenContract = new web3.eth.Contract(TokenFarm, TOKEN_FARM_ADDRESS);
  const promisesProfit = [];
  const promisesStake = [];

  try {
    for (const item of listProfileId) {
      promisesProfit.push(tokenContract.methods.getCurrentProfit(item).call());

      promisesStake.push(
        tokenContract.methods.getCurrentStakeUnlock(item).call()
      );
    }

    const resultProfit = await Promise.allSettled(promisesProfit);
    const resultStake = await Promise.allSettled(promisesStake);

    const formatDataProfit = {};
    const formatDataStake = {};

    resultProfit.forEach((profit, index) => {
      formatDataProfit[listProfileId[index]] = profit.value
        ? +convertToTokens(profit.value, web3)
        : 0;
    });

    resultStake.forEach((stake, index) => {
      formatDataStake[listProfileId[index]] = stake.value
        ? +convertToTokens(stake.value, web3)
        : 0;
    });

    return {
      resultProfit: formatDataProfit,
      resultStake: formatDataStake,
    };
  } catch (e) {
    console.log(e);
  }
  return {
    resultProfit: {},
    resultStake: {},
  };
};

export const convertToTokens = (n, web3) => {
  return web3.utils.fromWei(n, "ether");
};

export const convertToWei = (n, web3) => {
  try {
    const a = web3.utils.toWei(n, "ether");
    return a;
  } catch (error) {
    console.log("error:", error);
  }
};
