import React, { useContext, useState } from "react";
import PopupClaimSuccess from "../PopupClaimSuccess";
import styles from "./BannerNotiDone.module.css";
import { isMobile } from "react-device-detect";
//import appContext from "../../../../../appContext";

const BannerNotiDone = ({
  web3,
  account,
  whiteLists,
  popupNotiClaim,
  setPopupNotiClaim,
  setBannerNoti,
  isDisabledClaim,
  setIsDisabledClaim,
  handleBlockScreen,
}) => {
  //const { setLoading } = useContext(appContext);
  const [isMoveOutSide, setIsMoveOutSide] = useState(false)


  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {

    setShowPopup(!showPopup);
  };

  // const updateStatus = async () => {
  //   try {
  //     const url = process.env.REACT_APP_API_UPDATE_USER_WHITE_LISTS + "";
  //     const urlMain = `${url}/${account}`;
  //     await axios.put(urlMain);
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };

  return (
    <>
      {popupNotiClaim && <PopupClaimSuccess />}

      {!showPopup
        ? (<div className={`modalBackground `} >
          <div className={styles.wrap_popup} onMouseLeave={() => setIsMoveOutSide(true)} onMouseMove={() => setIsMoveOutSide(false)} style={{ position: "relative" }}>
            <div className={isMoveOutSide && styles.banner_outSide}>
              <div className={styles.btn_claim}

                onClick={togglePopup}
              >
                <div >
                  <img src="/images/ButtonClosePopupSuccess.png" className={styles.btn_receive}></img>
                </div>

              </div>
              {
                isMobile ? <img src="/images/banner-done-mobi.png" ></img> : <img src="/images/banner.png" ></img>
              }

            </div>
          </div>
        </div>)
        : ("")
      }

    </>
  );
};

export default BannerNotiDone;

{/* <BannerNotiIAgreen
            web3={web3}
            account={account}
            whiteLists={whiteLists}
            popupNotiClaim={popupNotiClaim}
            setPopupNotiClaim={setPopupNotiClaim}
            setBannerNoti={setBannerNoti}
            isDisabledClaim={isDisabledClaim}
            setIsDisabledClaim={setIsDisabledClaim}
            handleBlockScreen={handleBlockScreen}
          /> */}