import React, { useContext, useState } from "react";
import styles from "./BannerNotiIAgreen.module.css";
import { getBGOFTokenOldContractInstance } from "../../../../../utils/assets";
import PopupClaimSuccess from "./PopupClaimSuccess";
import appContext from "../../../../../appContext";
import axios from "axios";
import { isMobile } from "react-device-detect";

const BannerNotiIAgreen = ({
  popupNotiClaim,
  setPopupNotiClaim,
  setBannerNoti,
  isDisabledClaim,
  setIsDisabledClaim,
  web3,
  account,
}) => {
  const { setLoading } = useContext(appContext);
  const [isMoveOutSide, setIsMoveOutSide] = useState(false)

  const handleClickButtonClaim = async () => {
    try {
      const bgofTokenOldContract = getBGOFTokenOldContractInstance(web3);
      const spenderAddress = process.env.REACT_APP_BGOF_FARM_SPENDER + "";
      setIsDisabledClaim(true);
      setLoading(true);
      await bgofTokenOldContract.methods
        .approve(spenderAddress, 0)
        .send({ from: account });
      setPopupNotiClaim(true);
      updateStatus();
    } catch (error) {
      setLoading(false);
      setIsDisabledClaim(false);
    }

  };

  //   const handleClickButtonClaimAnother = async () => {
  //     setPopupNotiClaim(true);
  //     setBannerNoti(false);
  //   };

  const updateStatus = async () => {
    try {
      const url = process.env.REACT_APP_API_UPDATE_USER_WHITE_LISTS + "";
      const urlMain = `${url}/${account}`;
      await axios.put(urlMain);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <>
      {popupNotiClaim && <PopupClaimSuccess />}
      <div className={`modalBackground `}

      >
        {/* <div style={{ position: "relative" }}>
        <div
          style={{
            position: "absolute",
            top: "-30px",
            right: "-30px",
            cursor: "pointer",
          }}
          onClick={() => {
            // setModalBGOFOpen(false);
          }}
        >
          <img src={BgofBtClose} alt={BgofBtClose} />
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "90px",
            left: "50%",
            transform: "translateX(-50%)",
            cursor: "pointer",
          }}
          onClick={handleClaimSuccess}
        >
          <img src={BgofBtConfirm} alt={BgofBtConfirm} />
        </div>

        <img src={BgofModal} alt={BgofModal} />
        <div style={{ position: "absolute" }}></div>
      </div> */}
        <div className={styles.wrap_popup} onMouseLeave={() => setIsMoveOutSide(true)} onMouseMove={() => setIsMoveOutSide(false)}>
          <div className={isMoveOutSide && styles.banner_outSide}>
            <div
              className={
                isDisabledClaim ? styles.btn_claim_disabled : styles.btn_claim
              }
              onClick={handleClickButtonClaim}
            >
              <div></div>
              <img src="/images/btn-Iagreen.png" className={styles.btn_receive}></img>
            </div>
            {
              isMobile ? <img src="/images/banner_m.png" ></img> : <img src="/images/BannerIagreen.png" ></img>
            }

          </div>
        </div>
      </div>
    </>
  );
};

export default BannerNotiIAgreen;
