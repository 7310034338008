import React from "react";
import { Grid } from "semantic-ui-react";

import bgImage from "../../assets/images/guide-board.png";

const fakeData = [
  { id: 1, data: ["4%"] },
  { id: 2, data: ["4%"] },
  { id: 3, data: ["4%"] },
  { id: 4, data: ["4%"] },
  { id: 5, data: ["4%"] },
  { id: 6, data: ["4%"] },
  { id: 7, data: ["4%"] },
  { id: 8, data: ["4%"] },
  { id: 9, data: ["4%"] },
  { id: 10, data: ["4%"] },
  { id: 11, data: ["4%"] },
  { id: 12, data: ["4%"] },
  { id: 13, data: ["Harvest 100% "] },
];

const GuideDetail12Month = () => (
  <Grid width={12} celled className="guide-board-container no-shadow">
    {/* <img src={bgImage} className="guide-board-bg" alt="guide-board-bg" /> */}
    <Grid.Row
      width={12}
      className="no-shadow bg-inner-guide radius-top margin-guide-header"
    >
      <Grid.Column width={8} textAlign="center" className="no-shadow">
        <span style={{ fontSize: 14 }}>Duration Month</span>
      </Grid.Column>
      <Grid.Column width={8} textAlign="center" className="no-shadow">
        <span style={{ fontSize: 14 }}>12 Months Staking</span>
      </Grid.Column>
    </Grid.Row>
    {/* <div className="line-guide" /> */}
    {fakeData.map((item, index) => (
      <Grid.Row
        key={item.id.toString()}
        width={8}
        className={`no-shadow bg-inner-guide margin-guide-content ${
          index === fakeData.length - 1
            ? "radius-bottom margin-guide-content-bottom"
            : ""
        }`}
      >
        <Grid.Column
          width={8}
          textAlign="center"
          className={`no-shadow no-padding`}
        >
          <span
            className={`guide-text none-margin-left ${
              index % 2 === 0 ? "bg-A" : "bg-B"
            }`}
          >
            {index < 12 && index + 1}
          </span>
        </Grid.Column>
        <Grid.Column
          width={8}
          textAlign="center"
          className={`no-shadow no-padding`}
        >
          <span className={`guide-text ${index % 2 === 0 ? "bg-A" : "bg-B"}`}>
            {item.data[0]}
          </span>
        </Grid.Column>
      </Grid.Row>
    ))}
    {/* <Grid.Row>
      <Grid.Column width={3}>
        <span>1</span>
      </Grid.Column>
      <Grid.Column width={3}>
        <span>3 Months Staking</span>
      </Grid.Column>
      <Grid.Column width={3}>
        <span>6 Months Staking</span>
      </Grid.Column>
      <Grid.Column width={3}>
        <span>12 Months Staking</span>
      </Grid.Column>
    </Grid.Row> */}
  </Grid>
);

export default GuideDetail12Month;
